<template>
  <div>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div
                  class="container"
                 
                >
                  <router-link to="/demandecommandes">
                    <button class="btn btn-warning" style="margin-bottom: 30px">
                      Retour
                    </button>
                  </router-link>
                      <div style="margin-bottom: 20px;">
                      <h5>Les produits demandé par délégue :<b> " {{data.user_id['name']}}"</b></h5>

                                
                      </div>
                     <div class="table-responsive">
                      <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Reference</th>
                                    <th>Quantité demandé</th>

                                    <th>Quantité disponible</th>
                                  
                                    <th>Status stock</th>
                                    <!-- <th>Voir emplacements</th> -->

                                  </tr>
                                </thead>

                                <tbody>
                                  <tr
                                    v-for="(
                                      produit, index
                                    ) in produits"
                                    :key="index"
                                  >
                                    <td>
                                      {{ produit.produit_id.reference }}
                                    </td>
                               
                                      
                                    <td>{{ produit.quantites }}</td>
                                    <td>
                                      {{ produit.produit_id.quantites_disponible }}

                                      </td>
                                    <td>
                                      <span class="badge rounded-pill bg-success" v-if="produit.produit_id.quantites_disponible >0" >
                                      Produit disponible


                                      </span>
                                      <span class="badge rounded-pill bg-danger" v-if="produit.produit_id.quantites_disponible ==0" >

                                       Produit indisponible

                                      </span>
                                     </td>
                                     <!-- <td>
                                     <i
                                          class="mdi mdi-eye icone text-info"
                                          title="Voir les emplacements"
                                          v-if="produit.produit_id.quantites_disponible >0"
                                          data-toggle="modal" 
                                          :data-target="'#test' + produit.produit_id._id"
                                          >
                                      </i>
                                      <div
                        class="modal large"
                        :id="'test' + produit.produit_id._id"
                        tabindex="-1"
                        role="dialog"
                        @click.prevent="onestock(produit.produit_id._id)"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-lg" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                <span style="font-size: 13px"
                                  >Produit No =</span
                                >
                                <b>{{ produit.produit_id.reference }}</b>
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div
                                style="margin-top: 20px; margin-bottom: 20px"
                              >
                                <h5>Quantités totales :<b>{{produit.produit_id.quantites_disponible}}</b></h5>
                              </div>
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Code Emplacement</th>
                                    <th>Quantités</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr
                                    v-for="(
                                      emplacement, index
                                    ) in produit.emplacements"
                                    :key="index"
                                  >
                                    <td>
                                      #{{ emplacement.code_emplacement }}
                                    </td>

                                    <td>{{ emplacement.quantites }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
             
                          </div>
                        </div>
                      </div>


                                      <i
                                          class="mdi mdi-eye-off icone text-secondary"
                                          title="Voir les emplacements"
                                          v-if="produit.produit_id.quantites_disponible ==0"
                                          >
                                      </i>
                                     </td> -->
                                  </tr>
                                </tbody>
                              </table>
                      
                     </div>
                      







                      
                 
                </div>

                

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  data() {
    return {
      data: [],
      stock: [],
      produits:[]

    };
  },
  mounted() {
   this.stock=""

  },

  created() {
      this.onedata()
  },
  methods: {
  onedata() {
    HTTP
      .get("echantillons/getoneechantillons/" + this.$route.params.id)
      .then((response) => {
        this.data = response.data;
        this.produits =  this.data.produits;
     

      })
      .then((err) => console.log(err));
  },

  onestock(id) {
   this.stock=""
    HTTP
      .get("stocks/getsstocksbyproduit/" + id)
      .then((response) => {
        this.stock = response.data;
      })
      .then((err) => console.log(err));
  },

 
  },
};
</script>
